const FormRow = ({
  type,
  name,
  value,
  handleChange,
  placeholder,
  labelText,
  titleText,
  disabled,
}) => {
  return (
    <div className='form-row'>
      {titleText && (
        <label htmlFor={name} className='form-label'>
          {titleText}
        </label>
      )}
      <input
        id={name}
        type={type}
        value={value}
        name={name}
        onChange={handleChange}
        className='form-input'
        placeholder={placeholder || name}
        disabled={disabled ? true : false}
      />
      {labelText && (
        <label htmlFor={name} className='form-label'>
          {labelText}
        </label>
      )}
    </div>
  )
}

export default FormRow
