const FormCheckBox = ({
  name,
  checked,
  handleChange,
  text,
  float,
  alignItems,
}) => {
  return (
    <div
      className='form-row checkbox-wrapper'
      style={{ float: float, alignItems: alignItems }}
    >
      <input
        type='checkbox'
        checked={checked}
        onChange={handleChange}
        className='form-checkbox'
        name={name}
        id={name}
      />
      {text && (
        <label htmlFor={name} className='form-checkbox-text'>
          {text}
        </label>
      )}
    </div>
  )
}

export default FormCheckBox
