import customFetch, { checkForUnauthorizedResponse } from '../../utils/axios'

export const getModuleInfoThunk = async (id, thunkAPI) => {
  try {
    let url = `/modules/${id}/myrecordings`
    const resp = await customFetch.get(url)
    return resp.data
  } catch (error) {
    return checkForUnauthorizedResponse(error, thunkAPI)
  }
}
