import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { getModuleInfoThunk } from './moduleInfoThunk'
import { toast } from 'react-toastify'

const initialState = {
  isLoading: false,
  recording: null,
  lastVersion: null,
  subscribed: false,
  shared: false,
}

export const getModuleInfo = createAsyncThunk(
  'moduleInfo',
  async (id, thunkAPI) => {
    return getModuleInfoThunk(id, thunkAPI)
  }
)

const moduleInfoSlice = createSlice({
  name: 'moduleInfo',
  initialState,
  reducers: {
    toogleSubscribed: (state) => {
      state.subscribed = !state.subscribed
    },
    toogleShared: (state) => {
      state.shared = !state.shared
    },
  },
  extraReducers: {
    [getModuleInfo.pending]: (state) => {
      state.isLoading = true
    },
    [getModuleInfo.fulfilled]: (state, { payload }) => {
      state.isLoading = false
      state.recording = payload.recording
      state.lastVersion = payload.lastVersion
      state.subscribed = payload.subscribed
      state.shared = payload.shared
    },
    [getModuleInfo.rejected]: (state, { payload }) => {
      state.isLoading = false
      toast.error(payload)
    },
  },
})

export const { toogleSubscribed, toogleShared } = moduleInfoSlice.actions

export default moduleInfoSlice.reducer
