import Iframe from 'react-iframe'
import { usePath } from '../utils/queryParams'

const Module = () => {
  const path = usePath()
  if (path) {
    return (
      <Iframe
        url={path}
        width='100%'
        height='100%'
        id='module'
        className=''
        display='block'
        allowFullScreen='true'
        position='absolute'
        sandbox={['allow-scripts', 'allow-same-origin']}
      />
    )
  }
}
export default Module
