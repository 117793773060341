import styled from 'styled-components'

const Wrapper = styled.footer`
  width: 90%;
  max-width: 1518px;
  margin: 1rem auto;
  .footer-img {
    width: 100%;
    height: 13px;
    object-fit: cover;
    object-position: left;
  }
  .footer-text {
    text-align: center;
    margin-top: 1rem;
  }
  .footer-link {
    color: var(--primary-400);
  }
`
export default Wrapper
