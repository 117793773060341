import axios from 'axios'
import { logout } from '../features/user/userSlice'
import env from 'react-dotenv'

const backofficePath = env.BACKOFFICE_API

const customFetch = axios.create({
  baseURL: backofficePath,
  withCredentials: true,
})

customFetch.interceptors.request.use(
  (config) => {
    // const user = getUserFromLocalStorage()
    // if (user) {
    //   config.headers['Authorization'] = `Bearer ${user.token}`
    // }
    return config
  },
  (error) => {
    return Promise.reject(error)
  }
)

export default customFetch

export const checkForUnauthorizedResponse = (error, thunkAPI) => {
  if (error.response.status === 401) {
    thunkAPI.dispatch(logout())
    return thunkAPI.rejectWithValue(
      `Vous n'avez pas l'autorisation de voir cette ressource !`
    )
  }
  return thunkAPI.rejectWithValue(error.response.data.msg)
}
