import moment from 'moment'
import { Link } from 'react-router-dom'

const ModuleVersionInfo = ({ lastVersion }) => {
  const {
    version,
    changes,
    path,
    isBeta,
    numOfLaunched,
    numOfFinished,
    createdAt,
    updatedAt,
  } = lastVersion
  const createDate = `mis en ligne : ${moment(createdAt)
    .startOf('day')
    .fromNow()}`
  const updateDate = `mis à jour : ${moment(updatedAt)
    .startOf('day')
    .fromNow()}`

  return (
    <div>
      {version && <p>N° de version : {version}</p>}
      {changes && <p>Changements : {changes}</p>}
      {path && (
        <>
          <Link to={`/module?path=${path}`} target='_blank'>
            Accès au module en iframe
          </Link>
          <br />
          <a target='_blank' rel='noreferrer' href={`${path}`}>
            Accès au module en direct
          </a>
        </>
      )}
      {isBeta ? <p>En bêta</p> : <p>En production</p>}
      {numOfLaunched != null && (
        <p>Cette version du module a été démarrée : {numOfLaunched} fois</p>
      )}
      {numOfFinished != null && (
        <p>Cette version du module a été terminée : {numOfFinished} fois</p>
      )}
      {createDate && <p>{createDate} </p>}
      {updateDate && <p>{updateDate} </p>}
    </div>
  )
}
export default ModuleVersionInfo
