import { useState } from 'react'
import Modal from 'react-modal'
import { useDispatch } from 'react-redux'
import Loading from './Loading'

Modal.setAppElement('#root')

const customModalStyle = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    textAlign: 'center',
    transform: 'translate(-50%, -50%)',
  },
}

const ButtonModalDanger = ({
  isLoading,
  confirmFunction,
  callback,
  textButton,
  textModal,
}) => {
  const [modalIsOpen, setIsOpen] = useState(false)
  const dispatch = useDispatch()

  function openModal(e) {
    e.preventDefault()
    setIsOpen(true)
  }

  function closeModal() {
    setIsOpen(false)
  }

  function closeModalAndYes() {
    setIsOpen(false)
    dispatch(confirmFunction()).then(() => {
      dispatch(callback()) //
    })
  }

  if (isLoading) {
    return <Loading center />
  }

  return (
    <>
      <button
        className='btn btn-block btn-danger'
        disabled={isLoading}
        onClick={openModal}
      >
        {textButton}
      </button>
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        contentLabel='Example Modal'
        style={customModalStyle}
      >
        <h5>{textModal}</h5>
        <div>
          <button className='btn btn-block' onClick={closeModalAndYes}>
            Oui
          </button>
        </div>
        <hr />
        <div>
          <button className='btn btn-block' onClick={closeModal}>
            Non
          </button>
        </div>
      </Modal>
    </>
  )
}
export default ButtonModalDanger
