import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { toast } from 'react-toastify'
import { getAllModulesThunk } from './allModulesThunk'

const initialFiltersState = {
  search: '',
  searchStatus: 'all',
  sort: 'latest',
  sortOptions: ['latest', 'oldest', 'a-z', 'z-a'],
}

const initialState = {
  isLoading: false,
  modules: [],
  totalModules: 0,
  numOfPages: 1,
  page: 1,
  // stats: {},
  // monthlyApplications: [],
  ...initialFiltersState,
}

export const getAllModules = createAsyncThunk('modules', getAllModulesThunk)

// export const showStats = createAsyncThunk('allJobs/showStats', showStatsThunk)

const allModulesSlice = createSlice({
  name: 'allModules',
  initialState,
  reducers: {
    showLoading: (state) => {
      state.isLoading = true
    },
    hideLoading: (state) => {
      state.isLoading = false
    },
    handleChange: (state, { payload: { name, value } }) => {
      state.page = 1
      state[name] = value
    },
    clearFilters: (state) => {
      return { ...state, ...initialFiltersState }
    },
    changePage: (state, { payload }) => {
      state.page = payload
    },
    clearAllModulesState: () => initialState,
  },
  extraReducers: {
    [getAllModules.pending]: (state) => {
      state.isLoading = true
    },
    [getAllModules.fulfilled]: (state, { payload }) => {
      state.isLoading = false
      state.modules = payload.modules
      state.numOfPages = payload.numOfPages
      state.totalModules = payload.totalModules
    },
    [getAllModules.rejected]: (state, { payload }) => {
      state.isLoading = false
      toast.error(payload)
    },
    // [showStats.pending]: (state) => {
    //   state.isLoading = true
    // },
    // [showStats.fulfilled]: (state, { payload }) => {
    //   state.isLoading = false
    //   state.stats = payload.defaultStats
    //   state.monthlyApplications = payload.monthlyApplications
    // },
    // [showStats.rejected]: (state, { payload }) => {
    //   state.isLoading = false
    //   toast.error(payload)
    // },
  },
})

export const {
  showLoading,
  hideLoading,
  handleChange,
  clearFilters,
  changePage,
  clearAllModulesState,
} = allModulesSlice.actions

export default allModulesSlice.reducer
