import { useState, useEffect } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { FormRow, FormCheckBox, BigLogo, TwoLinesTitle } from '../components'
import Wrapper from '../assets/wrappers/Register'
import { toast } from 'react-toastify'
import { useSelector, useDispatch } from 'react-redux'
import { loginUser, registerUser } from '../features/user/userSlice'
import {
  addRememberMeToLocalStorage,
  removeRememberMeFromLocalStorage,
  getRememberMeFromLocalStorage,
} from '../utils/localStorage'
import { useRedirection } from '../utils/queryParams'

import ecosystem from '../assets/images/ecosystem-img.jpg'
import ecosystem2 from '../assets/images/ecosystem-2-img.jpg'

const initialState = {
  name: '',
  lastName: '',
  email: '',
  password: '',
  checkConditions: false,
  rememberMe: false,
  isMember: true,
  redirect: '',
}

const Register = (props) => {
  if (props.register === true) {
    initialState.isMember = false
  } else {
    initialState.isMember = true
  }
  initialState.redirect = useRedirection()
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const { isLoading, user } = useSelector((store) => store.user)
  const [values, setValues] = useState(initialState)

  const handleChange = (e) => {
    const name = e.target.name
    const value = e.target.value
    setValues({ ...values, [name]: value })
  }

  const onSubmit = (e) => {
    e.preventDefault()
    const {
      name,
      lastName,
      email,
      password,
      isMember,
      checkConditions,
      rememberMe,
      redirect,
    } = values
    if (
      !email ||
      (isMember && !password) ||
      (!isMember && (!name || !lastName))
    ) {
      toast.error('Merci de renseigner tous les champs')
      return
    }
    if (!isMember && !checkConditions) {
      toast.error(`Merci de cocher les conditions générales d'utilisation`)
      return
    }
    if (isMember) {
      if (rememberMe) {
        addRememberMeToLocalStorage({ email: email })
      } else {
        removeRememberMeFromLocalStorage()
      }
      dispatch(
        loginUser({ email: email, password: password, redirect: redirect })
      )
      return
    }
    dispatch(registerUser({ name, lastName, email }))
    // reset all cells
    cleanValues()
  }

  const cleanValues = () => {
    setValues({
      ...values,
      name: '',
      lastName: '',
      email: '',
      password: '',
      checkConditions: false,
    })
  }

  const toggleCheckConditions = () => {
    setValues({ ...values, checkConditions: !values.checkConditions })
  }

  const toggleRememberMe = () => {
    setValues({ ...values, rememberMe: !values.rememberMe })
  }

  const toggleMember = () => {
    setValues({ ...values, isMember: !values.isMember })
  }

  useEffect(() => {
    if (user) {
      if (initialState.redirect) {
        window.location.href = initialState.redirect
      } else {
        navigate('/')
      }
    }
  }, [user, navigate])

  useEffect(() => {
    cleanValues()
    if (values.isMember) {
      if (getRememberMeFromLocalStorage() != null) {
        setValues({
          ...values,
          rememberMe: true,
          email: getRememberMeFromLocalStorage().email,
        })
      }
    }
  }, [values.isMember])

  return (
    <Wrapper className='full-page'>
      {values.isMember ? (
        <img
          className='horizontal-banner'
          src={ecosystem2}
          alt='Votre nouvel écosysteme de formation'
        />
      ) : (
        <img
          className='horizontal-banner'
          src={ecosystem}
          alt='Votre nouvel écosysteme de formation'
        />
      )}
      <form className='form background-lagoon' onSubmit={onSubmit}>
        {/*Big Logo  */}
        <BigLogo />
        <hr />
        {/* Two Lines Title */}
        {values.isMember ? (
          <TwoLinesTitle title1='Se' title2='Connecter' />
        ) : (
          <TwoLinesTitle title1='Créer' title2='un compte' />
        )}
        <hr />

        {/* toggle name */}

        {!values.isMember && (
          <FormRow
            type='text'
            name='name'
            value={values.name}
            handleChange={handleChange}
            placeholder='Nom'
          />
        )}

        {/* toggle lastName */}

        {!values.isMember && (
          <FormRow
            type='text'
            name='lastName'
            value={values.lastName}
            handleChange={handleChange}
            placeholder='Prénom'
          />
        )}

        {/*toggle email */}
        {!values.isMember ? (
          <FormRow
            type='email'
            name='email'
            value={values.email}
            handleChange={handleChange}
            placeholder='Email*'
            labelText='*Pensez à vous inscrire avec votre mail personnel'
          />
        ) : (
          <FormRow
            type='email'
            name='email'
            value={values.email}
            handleChange={handleChange}
            placeholder='Email'
          />
        )}

        {/* toggle password */}
        {values.isMember && (
          <FormRow
            type='password'
            name='password'
            value={values.password}
            handleChange={handleChange}
            placeholder='Mot de passe'
          />
        )}

        {/* toggle conditions check box / remember me */}

        {values.isMember ? (
          <FormCheckBox
            name='rememberMe'
            checked={values.rememberMe}
            handleChange={toggleRememberMe}
            text='Se souvenir de moi'
            float='right'
            alignItems='center'
          />
        ) : (
          <FormCheckBox
            name='checkConditions'
            checked={values.checkConditions}
            handleChange={toggleCheckConditions}
            text="En vous inscrivant, vous acceptez nos conditions générales. Découvrez comment nous recueillons, utilisons et partageons vos données en regardant la vidéo sur notre politique de confidentialité et comment nous utilisons les cookies et autres technologies similaires en consultant notre Politique d'utilisation des cookies"
          />
        )}

        <button type='submit' className='btn btn-block' disabled={isLoading}>
          {isLoading ? 'Chargement ...' : 'Confirmer'}
        </button>

        {/*mot de passe oublié */}

        {values.isMember && (
          <p>
            <Link to='/forgot-password' className='member-btn'>
              Mot de passe oublié
            </Link>
          </p>
        )}

        <p>
          {values.isMember ? (
            <span>Pas encore inscrit ?</span>
          ) : (
            <span>Déjà inscrit ?</span>
          )}
          <br />

          <button type='button' onClick={toggleMember} className='member-btn'>
            {values.isMember ? `S'inscrire` : 'Se connecter'}
          </button>
        </p>
      </form>
    </Wrapper>
  )
}

export default Register
