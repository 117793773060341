export const useRedirection = () => {
  const queryParams = new URLSearchParams(window.location.search)
  const redirectionUrl = queryParams.get('redirect')
  return redirectionUrl
}

export const usePath = () => {
  const queryParams = new URLSearchParams(window.location.search)
  const redirectionUrl = queryParams.get('path')
  return redirectionUrl
}
