import Wrapper from '../assets/wrappers/TwoLinesTitle'

const TwoLinesTitle = ({ title1, title2 }) => {
  return (
    <Wrapper>
      <h4>
        <span className='title-lighten'>
          {title1}
          <br />
        </span>
        <span>{title2}</span>
      </h4>
    </Wrapper>
  )
}
export default TwoLinesTitle
