import {
  ConfigurationContainer,
  DataSharingContainer,
  ProfileContainer,
  SubscriptionContainer,
} from '../components'

const Profile = () => {
  return (
    <>
      <ProfileContainer />
      <SubscriptionContainer />
      <DataSharingContainer />
      <ConfigurationContainer />
    </>
  )
}
export default Profile
