export const addUserToLocalStorage = (user) => {
  localStorage.setItem('user', JSON.stringify(user))
}

export const removeUserFromLocalStorage = () => {
  localStorage.removeItem('user')
}

export const getUserFromLocalStorage = () => {
  const result = localStorage.getItem('user')
  const user = result ? JSON.parse(result) : null
  return user
}

export const addRememberMeToLocalStorage = (rememberMe) => {
  localStorage.setItem('rememberMe', JSON.stringify(rememberMe))
}

export const removeRememberMeFromLocalStorage = () => {
  localStorage.removeItem('rememberMe')
}

export const getRememberMeFromLocalStorage = () => {
  const result = localStorage.getItem('rememberMe')
  const rememberMe = result ? JSON.parse(result) : null
  return rememberMe
}
