import styled from 'styled-components'

const Wrapper = styled.div`
  h4 {
    text-align: center;
  }
  .title-lighten {
    color: var(--primary-500);
  }
`
export default Wrapper
