import { Navigate } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { toast } from 'react-toastify'

const ProtectedRoute = ({ children }) => {
  const { user } = useSelector((store) => store.user)
  if (!user) {
    toast.error('Vous devez vous connecter pour accéder à ce contenu')
    return <Navigate to='/catalog' />
  }
  return children
}

export default ProtectedRoute
