import styled from 'styled-components'

const Wrapper = styled.div`
  margin-top: 0rem;
  margin-left: 1rem;
  align-items: center;
  text-align: center;

  .finished {
    font-size: 2rem;
    svg {
      color: var(--green-dark);
    }
  }
  .text {
    letter-spacing: var(--letterSpacing);
  }
`
export default Wrapper
