import { useDispatch, useSelector } from 'react-redux'
import {
  getModuleInfo,
  toogleSubscribed,
  toogleShared,
} from '../features/moduleInfo/moduleInfoSlice'
import { useParams } from 'react-router-dom'
import Wrapper from '../assets/wrappers/IconText'
import { useEffect } from 'react'
import Loading from '../components/Loading'
import env from 'react-dotenv'
import { toast } from 'react-toastify'
import customFetch from '../utils/axios'
import { ModuleVersionInfo } from '../components'

const ModuleInfo = () => {
  const { id } = useParams()
  const dispatch = useDispatch()
  const { isLoading, lastVersion, recording, subscribed, shared } = useSelector(
    (store) => store.moduleInfo
  )

  useEffect(() => {
    dispatch(getModuleInfo(id))
  }, [])

  const subscribeToModule = async () => {
    if (env.NODE_ENV === 'production' && 'serviceWorker' in navigator) {
      const publicVapidKey = env.VAPID_PUBLIC_KEY
      navigator.serviceWorker.ready
        .then((registration) => {
          registration.pushManager
            .subscribe({
              userVisibleOnly: true,
              applicationServerKey: publicVapidKey,
            })
            .then((subscription) => {
              const url = `${env.BACKOFFICE_API}/modules/${id}/subscribe`
              customFetch.post(url, subscription).then(() => {
                dispatch(toogleSubscribed())
              })
            })
        })
        .catch((error) => {
          toast.error(error.message)
        })
    } else {
      toast.error('Ne fonctionne uniquement en production')
    }
  }

  const unSubscribeToModule = async () => {
    // if (env.NODE_ENV === 'production' && 'serviceWorker' in navigator) {
    const url = `${env.BACKOFFICE_API}/modules/${id}/unsubscribe`
    customFetch.delete(url).then(() => {
      dispatch(toogleSubscribed())
    })
  }

  const shareModuleData = async () => {
    const url = `${env.BACKOFFICE_API}/modules/${id}/share`
    customFetch.get(url).then(() => {
      dispatch(toogleShared())
    })
  }

  const unshareModuleData = async () => {
    // if (env.NODE_ENV === 'production' && 'serviceWorker' in navigator) {
    const url = `${env.BACKOFFICE_API}/modules/${id}/unshare`
    customFetch.delete(url).then(() => {
      dispatch(toogleShared())
    })
  }

  if (isLoading) {
    return <Loading center />
  }

  // recording parameters
  var progress = null
  if (recording) {
    progress = recording.progress
  }

  return (
    <Wrapper>
      {lastVersion !== null && <ModuleVersionInfo lastVersion={lastVersion} />}

      <div>
        {progress !== null && (
          <p>
            Terminé à <b>{progress}%</b>
          </p>
        )}
      </div>
      <div>
        {subscribed === true ? (
          <button onClick={unSubscribeToModule} className='btn btn-block'>
            Se désabonner des notifications de ce module
          </button>
        ) : (
          <button onClick={subscribeToModule} className='btn btn-block'>
            Recevoir en notification les mises à jour de ce module
          </button>
        )}
      </div>
      <div>
        {shared === true ? (
          <button onClick={unshareModuleData} className='btn btn-block'>
            Ne pas partager ses données aux recruteurs et formateurs
          </button>
        ) : (
          <button onClick={shareModuleData} className='btn btn-block'>
            Ouvrir ses données aux recruteurs et formateurs
          </button>
        )}
      </div>
    </Wrapper>
  )
}
export default ModuleInfo
