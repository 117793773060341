import customFetch, { checkForUnauthorizedResponse } from '../../utils/axios'

const repeatFetchingUnsharedModule = (modules) => {
  const promises = modules.values.map(async (module) => {
    const url = `/modules/${module}/unshare`
    return {
      id: url,
      myValue: await customFetch.delete(url),
    }
  })
  return Promise.all(promises)
}

export const deleteModuleDataSharingThunk = async (modules, thunkAPI) => {
  try {
    return repeatFetchingUnsharedModule(modules)
  } catch (error) {
    if (error.response.status === 401) {
      return thunkAPI.rejectWithValue('Unauthorized! ')
    }
    return checkForUnauthorizedResponse(error, thunkAPI)
  }
}

export const getMyModulesDataSharedThunk = async (thunkAPI) => {
  try {
    let url = `/moduledatasharing/my`
    const resp = await customFetch.get(url)
    return resp.data
  } catch (error) {
    return checkForUnauthorizedResponse(error, thunkAPI)
  }
}

export const deleteAllModulesDataSharingThunk = async (thunkAPI) => {
  try {
    let url = `/moduledatasharing/my`
    const resp = await customFetch.delete(url)
    return resp.data
  } catch (error) {
    return checkForUnauthorizedResponse(error, thunkAPI)
  }
}
