import { useState } from 'react'
import {
  BigLogo,
  FormRow,
  PasswordCheckList,
  TwoLinesTitle,
} from '../components'
import Wrapper from '../assets/wrappers/Register'
import { toast } from 'react-toastify'
import { useSelector, useDispatch } from 'react-redux'
import { resetPasswordUser } from '../features/user/userSlice'
import { Link, useLocation } from 'react-router-dom'

function useQuery() {
  return new URLSearchParams(useLocation().search)
}

const initialState = {
  password: '',
  password2: '',
  email: '',
  token: '',
  passwordValid: '',
}

const ResetPassword = () => {
  const query = useQuery()
  const dispatch = useDispatch()
  const { isLoading, isPasswordChanged } = useSelector((store) => store.user)
  const [values, setValues] = useState(initialState)

  const handleChange = (e) => {
    const name = e.target.name
    const value = e.target.value
    setValues({ ...values, [name]: value })
  }

  const onSubmit = (e) => {
    e.preventDefault()
    const email = query.get('email')
    const token = query.get('token')
    const { password, password2, passwordValid } = values
    if (!password || !password2) {
      toast.error('Merci de renseigner tous les champs')
      return
    }
    if (!passwordValid) {
      toast.error(`Le mot de passe n'est pas conforme`)
      return
    }
    dispatch(resetPasswordUser({ password, email, token }))
    // reset all cells
    setValues({
      ...values,
      password: '',
      password2: '',
    })
  }

  return (
    <Wrapper className='full-page'>
      <form className='form' onSubmit={onSubmit}>
        {/* Big Logo */}
        <BigLogo />
        <hr />
        {!isPasswordChanged ? (
          <>
            {/* Two Lines Title */}

            <TwoLinesTitle title1='Changer' title2='son mot de passe' />
            <hr />

            {/*password */}
            <FormRow
              type='password'
              name='password'
              value={values.password}
              handleChange={handleChange}
              placeholder='Mot de passe'
            />

            {/*password 2 */}
            <FormRow
              type='password'
              name='password2'
              value={values.password2}
              handleChange={handleChange}
              placeholder='Confirmer son mot de passe'
            />

            <PasswordCheckList
              values={values}
              password2={values}
              setValues={setValues}
            />

            <button
              type='submit'
              className='btn btn-block'
              disabled={isLoading}
            >
              {isLoading ? 'Chargement ...' : 'Valider'}
            </button>
          </>
        ) : (
          <div className='center'>
            <p>Votre mot de passe a bien été changé !</p>
            <p>
              Vous pouvez vous connecter à la plateforme Lagoon avec vos
              nouveaux identifiants.
            </p>
            <Link to='/login' className='btn'>
              Login
            </Link>
          </div>
        )}
      </form>
    </Wrapper>
  )
}

export default ResetPassword
