import { GiConfirmed } from 'react-icons/gi'
import Wrapper from '../assets/wrappers/ModuleRecordingPanel'
import moment from 'moment'

const ModuleRecordingsPanel = ({ recordings }) => {
  if (recordings && recordings.length > 0) {
    const { updatedAt, finished, progress } = recordings[recordings.length - 1]
    const stringProgress = `Completion : ${progress}%`
    const lastAttempt = `Dernière tentative : ${moment(updatedAt)
      .startOf('day')
      .fromNow()}`
    return (
      <Wrapper className='recording'>
        {finished && (
          <div className='finished'>
            <GiConfirmed />
          </div>
        )}
        <div>
          <span className='text'>{lastAttempt}</span>
        </div>
        <div>
          <span className='text'>{stringProgress}</span>
        </div>
      </Wrapper>
    )
  }
  return
}
export default ModuleRecordingsPanel
