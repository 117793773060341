import { FormRow } from '.'
import Wrapper from '../assets/wrappers/SearchContainer'
import { useSelector, useDispatch } from 'react-redux'
import { updateUser } from '../features/user/userSlice'
import { useState } from 'react'
import { toast } from 'react-toastify'

const ProfileContainer = () => {
  const dispatch = useDispatch()

  const { isLoading, user } = useSelector((store) => store.user)

  const initialState = {
    name: user.name,
    lastName: user.lastName,
    email: user.email,
  }

  const [values, setValues] = useState(initialState)

  const handleChange = (e) => {
    const name = e.target.name
    const value = e.target.value
    console.log(e.target.value)
    setValues({ ...values, [name]: value })
  }

  const onSubmit = (e) => {
    e.preventDefault()
    const { name, lastName } = values
    if (!name || !lastName) {
      toast.error('Merci de renseigner tous les champs')
      return
    }
    dispatch(updateUser({ name, lastName }))
    return
  }

  return (
    <Wrapper>
      <form className='form'>
        <h5>Mon Profil</h5>
        <div className='form-center'>
          {/* email position */}
          <FormRow
            type='text'
            name='email'
            titleText='Email (non modifiable ici)'
            placeholder='E-mail'
            value={values.email}
            disabled={true}
          />
          {/* name position */}
          <FormRow
            type='text'
            name='name'
            titleText='Nom'
            placeholder='Nom'
            value={values.name}
            handleChange={handleChange}
          />
          {/* last position */}
          <FormRow
            type='text'
            name='lastName'
            titleText='Prénom'
            placeholder='Prénom'
            value={values.lastName}
            handleChange={handleChange}
          />
          <button
            className='btn btn-block'
            disabled={isLoading || values.lastName == '' || values.name == ''}
            onClick={onSubmit}
          >
            Confirmer
          </button>
        </div>
      </form>
    </Wrapper>
  )
}
export default ProfileContainer
