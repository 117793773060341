import { FormRow, FormRowSelect } from '.'
import { AiFillMinusSquare, AiFillPlusSquare } from 'react-icons/ai'
import Wrapper from '../assets/wrappers/SearchContainer'
import { useSelector, useDispatch } from 'react-redux'
import {
  handleChange,
  clearFilters,
} from '../features/allModules/allModulesSlice'
import { useState, useMemo } from 'react'

const SearchContainer = () => {
  const [localSearch, setLocalSearch] = useState('')
  const [isShrinked, setIsShrinked] = useState(true)

  const { isLoading, searchStatus, sort, sortOptions } = useSelector(
    (store) => store.allModules
  )
  const statusOptions = ['approuved', 'pending', 'removed']
  const dispatch = useDispatch()
  const handleSearch = (e) => {
    dispatch(handleChange({ name: e.target.name, value: e.target.value }))
  }

  const debounce = () => {
    let timeoutID
    return (e) => {
      setLocalSearch(e.target.value)
      clearTimeout(timeoutID)
      timeoutID = setTimeout(() => {
        dispatch(handleChange({ name: e.target.name, value: e.target.value }))
      }, 1000)
    }
  }

  const optimizedDebounce = useMemo(() => {
    return debounce() // eslint-disable-next-line
  }, [])

  const handleSubmit = (e) => {
    e.preventDefault()
    setLocalSearch('')
    dispatch(clearFilters())
  }
  return (
    <Wrapper>
      <form className='form'>
        <h5>Filtres</h5>
        <button
          type='button'
          className='close-btn'
          onClick={() => setIsShrinked(!isShrinked)}
        >
          {isShrinked ? <AiFillPlusSquare /> : <AiFillMinusSquare />}
        </button>
        {!isShrinked && (
          <div className='form-center'>
            {/* search position */}

            <FormRow
              type='text'
              name='search'
              titleText='Nom et mots clés'
              placeholder='Rechercher'
              value={localSearch}
              handleChange={optimizedDebounce}
            />
            {/* search by status */}
            <FormRowSelect
              labelText='Status'
              name='searchStatus'
              value={searchStatus}
              handleChange={handleSearch}
              list={['all', ...statusOptions]}
            />
            {/* sort */}
            <FormRowSelect
              name='Trier par'
              value={sort}
              handleChange={handleSearch}
              list={sortOptions}
            />
            <button
              className='btn btn-block btn-danger'
              disabled={isLoading}
              onClick={handleSubmit}
            >
              Retirer les filtres
            </button>
          </div>
        )}
      </form>
    </Wrapper>
  )
}

export default SearchContainer
