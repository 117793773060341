import styled from 'styled-components'
import background from '../images/background-lagoon.png'

const Wrapper = styled.section`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: auto 3rem;

  .horizontal-banner {
    max-width: var(--fixed-width);
    border-radius: var(--big-borderRadius);
    /* border-top: 5px solid var(--primary-500); */
  }

  @media (max-width: 992px) {
    .horizontal-banner {
      display: none;
    }
  }

  .form {
    margin: 0;
    position: relative;
    /* border-top: 5px solid var(--primary-500); */
  }

  .background-lagoon:before {
    content: ' ';
    display: block;
    height: 511px;
    position: absolute;
    width: 315px;
    z-index: -1;
    right: -150px;
    top: calc(50% - 255px);
    top: -webkit-calc(50% - 255px);
    top: -o-calc(50% - 255px);
    top: -moz-calc(50% - 255px);

    background-image: url(${background});
    background-repeat: no-repeat;
  }

  @media (max-width: 992px) {
    .background-lagoon:before {
      display: none;
    }
  }

  .logo {
    max-width: 100%;
  }

  .center {
    text-align: center;
  }

  p {
    margin: 0;
    margin-top: 1rem;
    text-align: center;
  }
  .btn {
    margin-top: 1rem;
  }
  .member-btn {
    background: transparent;
    border: transparent;
    color: var(--primary-500);
    cursor: pointer;
    letter-spacing: var(--letterSpacing);
    text-decoration: underline;
  }
  .member-btn:hover {
    color: var(--primary-700);
  }
`
export default Wrapper
