import customFetch, { checkForUnauthorizedResponse } from '../../utils/axios'

export const registerUserThunk = async (url, user, thunkAPI) => {
  try {
    const resp = await customFetch.post(url, user)
    return resp.data
  } catch (error) {
    return thunkAPI.rejectWithValue(error.response.data.msg)
  }
}

export const verifyUserThunk = async (url, user, thunkAPI) => {
  try {
    const resp = await customFetch.post(url, user)
    return resp.data
  } catch (error) {
    return thunkAPI.rejectWithValue(error.response.data.msg)
  }
}
export const confirmAccountThunk = async (url, user, thunkAPI) => {
  try {
    const resp = await customFetch.post(url, {
      email: user.email,
      verificationToken: user.verificationToken,
      password: user.password,
    })
    return resp.data
  } catch (error) {
    return thunkAPI.rejectWithValue(error.response.data.msg)
  }
}

export const forgotPasswordThunk = async (url, user, thunkAPI) => {
  try {
    const resp = await customFetch.post(url, {
      email: user.email,
    })
    return resp.data
  } catch (error) {
    return thunkAPI.rejectWithValue(error.response.data.msg)
  }
}

export const resetPasswordThunk = async (url, user, thunkAPI) => {
  try {
    const resp = await customFetch.post(url, {
      password: user.password,
      email: user.email,
      token: user.token,
    })
    return resp.data
  } catch (error) {
    return thunkAPI.rejectWithValue(error.response.data.msg)
  }
}

export const loginUserThunk = async (url, user, thunkAPI) => {
  try {
    const resp = await customFetch.post(url, {
      password: user.password,
      email: user.email,
    })
    return resp.data
  } catch (error) {
    return thunkAPI.rejectWithValue(error.response.data.msg)
  }
}

export const updateUserThunk = async (url, user, thunkAPI) => {
  try {
    const resp = await customFetch.patch(url, user)
    return resp.data
  } catch (error) {
    // console.log(error.response);
    if (error.response.status === 401) {
      return thunkAPI.rejectWithValue('Unauthorized! ')
    }
    return checkForUnauthorizedResponse(error, thunkAPI)
  }
}

export const logoutThunk = async (url, thunkAPI) => {
  try {
    const resp = await customFetch.delete(url)
    return resp.data
  } catch (error) {
    return thunkAPI.rejectWithValue('Unable to logout')
  }
}

// export const clearStoreThunk = async (message, thunkAPI) => {
//   try {
//     // logout user
//     thunkAPI.dispatch(logoutUser(message))
//     // clear modules value
//     thunkAPI.dispatch(clearAllModulesState())
//     return Promise.resolve()
//   } catch (error) {
//     // console.log(error);
//     return Promise.reject()
//   }
// }
