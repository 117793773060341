import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { toast } from 'react-toastify'
import {
  getMyModulesSubscribedThunk,
  deleteModuleSubscriptionsThunk,
  deleteAllSubscriptionsThunk,
} from './subscriptionThunk'

const initialState = {
  isLoading: false,
  moduleSubscriptions: [],
}

export const getMyModulesSubscribed = createAsyncThunk(
  'subscription/getMyModulesSubscribed',
  async (thunkAPI) => {
    return getMyModulesSubscribedThunk(thunkAPI)
  }
)

export const deleteModuleSubscriptions = createAsyncThunk(
  'subscription/deleteModuleSubscriptions',
  async (modules, thunkAPI) => {
    return deleteModuleSubscriptionsThunk(modules, thunkAPI)
  }
)

export const deleteAllSubscriptions = createAsyncThunk(
  'subscription/deleteAllSubscriptions',
  async (thunkAPI) => {
    return deleteAllSubscriptionsThunk(thunkAPI)
  }
)

const subscriptionSlice = createSlice({
  name: 'subscription',
  initialState,
  extraReducers: (builder) => {
    builder
      .addCase(getMyModulesSubscribed.pending, (state) => {
        state.isLoading = true
      })
      .addCase(getMyModulesSubscribed.fulfilled, (state, { payload }) => {
        const { moduleSubscriptions } = payload
        state.isLoading = false
        state.moduleSubscriptions = moduleSubscriptions
      })
      .addCase(getMyModulesSubscribed.rejected, (state, { payload }) => {
        state.isLoading = false
        toast.error(payload)
      })
      .addCase(deleteModuleSubscriptions.pending, (state) => {
        state.isLoading = true
      })
      .addCase(deleteModuleSubscriptions.fulfilled, (state) => {
        state.isLoading = false
        toast.success(`Subscriptions mises à jour !`)
      })
      .addCase(deleteModuleSubscriptions.rejected, (state, { payload }) => {
        state.isLoading = false
        toast.error(payload)
      })
      .addCase(deleteAllSubscriptions.pending, (state) => {
        state.isLoading = true
      })
      .addCase(deleteAllSubscriptions.fulfilled, (state) => {
        state.isLoading = false
        toast.success(`Toutes vos notifications ont été supprimées`)
      })
      .addCase(deleteAllSubscriptions.rejected, (state, { payload }) => {
        state.isLoading = false
        toast.error(payload)
      })
  },
})

export default subscriptionSlice.reducer
