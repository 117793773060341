import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { toast } from 'react-toastify'
import {
  getUserFromLocalStorage,
  addUserToLocalStorage,
  removeUserFromLocalStorage,
} from '../../utils/localStorage'
import {
  loginUserThunk,
  registerUserThunk,
  updateUserThunk,
  logoutThunk,
  forgotPasswordThunk,
  resetPasswordThunk,
  verifyUserThunk,
  confirmAccountThunk,
} from './userThunk'

const initialState = {
  isLoading: false,
  isVerified: false,
  isPasswordChanged: false,
  isSidebarOpen: false,
  user: getUserFromLocalStorage(),
}

export const registerUser = createAsyncThunk(
  'user/registerUser',
  async (user, thunkAPI) => {
    return registerUserThunk('/auth/register', user, thunkAPI)
  }
)

export const verifyUser = createAsyncThunk(
  'user/verifyUser',
  async (user, thunkAPI) => {
    return verifyUserThunk('/auth/verify-email', user, thunkAPI)
  }
)

export const confirmAccount = createAsyncThunk(
  'user/confirmAccount',
  async (user, thunkAPI) => {
    return confirmAccountThunk('/auth/confirm-account', user, thunkAPI)
  }
)

export const resetPasswordUser = createAsyncThunk(
  'user/resetPasswordUser',
  async (user, thunkAPI) => {
    return resetPasswordThunk('/auth/reset-password', user, thunkAPI)
  }
)

export const forgotPasswordUser = createAsyncThunk(
  'user/forgotPasswordUser',
  async (user, thunkAPI) => {
    return forgotPasswordThunk('/auth/forgot-password', user, thunkAPI)
  }
)

export const loginUser = createAsyncThunk(
  'user/loginUser',
  async (user, thunkAPI) => {
    return loginUserThunk('/auth/login', user, thunkAPI)
  }
)

export const logout = createAsyncThunk(
  'user/logout',
  async (user, thunkAPI) => {
    return logoutThunk('/auth/logout', thunkAPI)
  }
)

export const updateUser = createAsyncThunk(
  'user/updateUser',
  async (user, thunkAPI) => {
    return updateUserThunk('/users/updateMe', user, thunkAPI)
  }
)

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    toggleSidebar: (state) => {
      state.isSidebarOpen = !state.isSidebarOpen
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(registerUser.pending, (state) => {
        state.isLoading = true
      })
      .addCase(registerUser.fulfilled, (state, { payload }) => {
        const { user } = payload
        state.isLoading = false
        state.user = user
        toast.success(
          `Un email de confirmation a été envoyé à l'adresse renseignée`
        )
      })
      .addCase(registerUser.rejected, (state, { payload }) => {
        state.isLoading = false
        toast.error(payload)
      })
      .addCase(verifyUser.pending, (state) => {
        state.isLoading = true
        state.isVerified = false
      })
      .addCase(verifyUser.fulfilled, (state) => {
        state.isLoading = false
        state.isVerified = true
      })
      .addCase(verifyUser.rejected, (state, { payload }) => {
        state.isLoading = false
        state.isVerified = false
        toast.error(payload)
      })
      .addCase(confirmAccount.pending, (state) => {
        state.isLoading = true
        state.isPasswordChanged = false
      })
      .addCase(confirmAccount.fulfilled, (state) => {
        state.isLoading = false
        state.isPasswordChanged = true
      })
      .addCase(confirmAccount.rejected, (state, { payload }) => {
        state.isLoading = false
        state.isPasswordChanged = false
        toast.error(payload)
      })
      .addCase(resetPasswordUser.pending, (state) => {
        state.isLoading = true
        state.isPasswordChanged = false
      })
      .addCase(resetPasswordUser.fulfilled, (state) => {
        state.isLoading = false
        state.isPasswordChanged = true
      })
      .addCase(resetPasswordUser.rejected, (state, { payload }) => {
        state.isLoading = false
        state.isPasswordChanged = false
        toast.error(payload)
      })
      .addCase(forgotPasswordUser.pending, (state) => {
        state.isLoading = true
      })
      .addCase(forgotPasswordUser.fulfilled, (state, { payload }) => {
        state.isLoading = false
        toast.success(payload.msg)
      })
      .addCase(forgotPasswordUser.rejected, (state, { payload }) => {
        state.isLoading = false
        toast.error(payload)
      })
      .addCase(loginUser.pending, (state) => {
        state.isLoading = true
      })
      .addCase(loginUser.fulfilled, (state, { payload }) => {
        const { user } = payload
        state.isLoading = false
        state.user = user
        addUserToLocalStorage(user)
        toast.success(`Bonjour ${user.lastName}`)
      })
      .addCase(loginUser.rejected, (state, { payload }) => {
        state.isLoading = false
        toast.error(payload)
      })
      .addCase(updateUser.pending, (state) => {
        state.isLoading = true
      })
      .addCase(updateUser.fulfilled, (state, { payload }) => {
        const { user } = payload
        state.isLoading = false
        state.user = user
        addUserToLocalStorage(user)
        toast.success(`Profil mis à jour !`)
      })
      .addCase(updateUser.rejected, (state, { payload }) => {
        state.isLoading = false
        toast.error(payload)
      })
      .addCase(logout.pending, (state) => {
        state.isLoading = true
      })
      .addCase(logout.fulfilled, (state) => {
        state.isLoading = false
        state.user = null
        removeUserFromLocalStorage()
        toast.success(`Deconnexion avec succès`)
      })
      .addCase(logout.rejected, (state) => {
        state.isLoading = false
        state.user = null
      })
  },
})

export const { toggleSidebar } = userSlice.actions

export default userSlice.reducer
