import customFetch, { checkForUnauthorizedResponse } from '../../utils/axios'

const repeatFetchingUnsubscribedModule = (modules) => {
  const promises = modules.values.map(async (module) => {
    const url = `/modules/${module}/unsubscribe`
    return {
      id: url,
      myValue: await customFetch.delete(url),
    }
  })
  return Promise.all(promises)
}

export const deleteModuleSubscriptionsThunk = async (modules, thunkAPI) => {
  try {
    return repeatFetchingUnsubscribedModule(modules)
  } catch (error) {
    if (error.response.status === 401) {
      return thunkAPI.rejectWithValue('Unauthorized! ')
    }
    return checkForUnauthorizedResponse(error, thunkAPI)
  }
}

export const getMyModulesSubscribedThunk = async (url, thunkAPI) => {
  try {
    let url = `/subscriptions/my`
    const resp = await customFetch.get(url)
    return resp.data
  } catch (error) {
    return checkForUnauthorizedResponse(error, thunkAPI)
  }
}

export const deleteAllSubscriptionsThunk = async (thunkAPI) => {
  try {
    let url = `/subscriptions/my`
    const resp = await customFetch.delete(url)
    return resp.data
  } catch (error) {
    return checkForUnauthorizedResponse(error, thunkAPI)
  }
}
