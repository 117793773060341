import { ButtonModalDanger } from '.'
import Wrapper from '../assets/wrappers/SearchContainer'
import { useSelector } from 'react-redux'
import {
  deleteAllModulesDataSharings,
  getMyModulesDataShared,
} from '../features/dataSharing/dataSharingSlice'
import {
  deleteAllSubscriptions,
  getMyModulesSubscribed,
} from '../features/subscription/subscriptionSlice'

const ConfigurationContainer = () => {
  const { isLoading: isLoadingDataShared } = useSelector(
    (store) => store.dataSharing
  )

  const { isLoading: isLoadingSubscription } = useSelector(
    (store) => store.subscription
  )

  return (
    <Wrapper>
      <form className='form'>
        <h5>Ma configuration</h5>
        <div className='form-center'>
          <ButtonModalDanger
            isLoading={isLoadingSubscription}
            confirmFunction={deleteAllSubscriptions}
            callback={getMyModulesSubscribed}
            textButton='Retirer toutes les notifications'
            textModal={[
              'Voulez vous confirmer cette action ?',
              <br />,
              'Vous perdrez toutes les notifications associées à votre compte.',
              <br />,
              'Cette action est définitive',
            ]}
          />
          <ButtonModalDanger
            isLoading={isLoadingDataShared}
            confirmFunction={deleteAllModulesDataSharings}
            callback={getMyModulesDataShared}
            textButton='Retirer tous les partages de données'
            textModal={[
              'Voulez vous confirmer cette action ?',
              <br />,
              'Vous perdrez tous les partages de données associées à votre compte.',
              <br />,
              'Cette action est définitive',
            ]}
          />
        </div>
      </form>
    </Wrapper>
  )
}

export default ConfigurationContainer
