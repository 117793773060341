import { Outlet } from 'react-router-dom'
import { Navbar, Footer, SmallSidebar, BigSidebar } from '../components'
import Wrapper from '../assets/wrappers/SharedNav'

const SharedNav = () => {
  return (
    <>
      <Wrapper>
        <main className='dashboard'>
          <SmallSidebar />
          <BigSidebar />
          <div>
            <Navbar />
            <div className='dashboard-page'>
              <Outlet />
            </div>
            <Footer />
          </div>
        </main>
      </Wrapper>
    </>
  )
}

export default SharedNav
