import { useState } from 'react'
import { BigLogo, FormRow, TwoLinesTitle } from '../components'
import Wrapper from '../assets/wrappers/Register'
import { toast } from 'react-toastify'
import { useSelector, useDispatch } from 'react-redux'
import { forgotPasswordUser } from '../features/user/userSlice'
import { Link } from 'react-router-dom'

const initialState = {
  email: '',
}

const ForgotPassword = () => {
  const dispatch = useDispatch()
  const { isLoading } = useSelector((store) => store.user)
  const [values, setValues] = useState(initialState)
  // redux toolkit and useNavigate later

  const handleChange = (e) => {
    const name = e.target.name
    const value = e.target.value
    setValues({ ...values, [name]: value })
  }

  const onSubmit = (e) => {
    e.preventDefault()
    const { email } = values
    if (!email) {
      toast.error('Merci de renseigner tous les champs')
      return
    }
    dispatch(forgotPasswordUser({ email }))
    // reset all cells
    setValues({
      ...values,
      email: '',
    })
  }

  return (
    <Wrapper className='full-page'>
      <form className='form' onSubmit={onSubmit}>
        {/* Big Logo */}
        <BigLogo />
        <hr />

        {/* Two Lines Title */}
        <TwoLinesTitle title1='Recupérez' title2='votre mot de passe' />
        <hr />

        {/*email */}
        <FormRow
          type='email'
          name='email'
          value={values.email}
          handleChange={handleChange}
        />

        <button type='submit' className='btn btn-block' disabled={isLoading}>
          {isLoading ? 'Chargement ...' : 'Confirmer'}
        </button>

        <p>
          <Link to='/login' className='member-btn'>
            Retour au login
          </Link>
        </p>
      </form>
    </Wrapper>
  )
}

export default ForgotPassword
