import { BrowserRouter, Routes, Route } from 'react-router-dom'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

import {
  Landing,
  Catalog,
  ModuleInfo,
  SharedNav,
  Register,
  Error,
  ProtectedRoute,
  ForgotPassword,
  ResetPassword,
  Verify,
  Logout,
  Module,
  Profile,
} from './pages'
import { Footer } from './components'

function App() {
  return (
    <BrowserRouter>
      <Routes>
        {/* Routes with navigation */}
        <Route path='/' element={<SharedNav />}>
          <Route index element={<Landing />} />
          <Route path='catalog' element={<Catalog />} />
          <Route
            path='catalog/:id'
            element={
              <ProtectedRoute>
                <ModuleInfo />
              </ProtectedRoute>
            }
          />
          <Route
            path='profile'
            element={
              <ProtectedRoute>
                <Profile />
              </ProtectedRoute>
            }
          />
        </Route>
        {/* Routes without navigation */}
        <Route
          path='module'
          element={
            <ProtectedRoute>
              <Module />
            </ProtectedRoute>
          }
        />
        <Route
          path='login'
          element={
            <>
              <Register />
              <Footer />
            </>
          }
        />
        <Route
          path='register'
          element={
            <>
              <Register register={true} />
              <Footer />
            </>
          }
        />
        <Route
          path='/forgot-password'
          element={
            <>
              <ForgotPassword />
              <Footer />
            </>
          }
        ></Route>
        <Route
          path='/user/verify-email'
          element={
            <>
              <Verify />
              <Footer />
            </>
          }
        ></Route>
        <Route
          path='/user/reset-password'
          element={
            <>
              <ResetPassword />
              <Footer />
            </>
          }
        ></Route>
        <Route path='logout' element={<Logout />} />
        <Route path='*' element={<Error />} />
      </Routes>
      <ToastContainer position='top-center' />
    </BrowserRouter>
  )
}

export default App
