import lineFooter from '../assets/images/line-footer.png'
import Wrapper from '../assets/wrappers/Footer.js'

const Footer = () => {
  return (
    <Wrapper>
      <img className='footer-img' src={lineFooter} alt='footer'></img>
      <div className='footer-text'>
        <span>Tous droits réservés © </span>
        <span className='footer-link'>lagoon.roselagon.com</span>
      </div>
    </Wrapper>
  )
}
export default Footer
