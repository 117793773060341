import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { toast } from 'react-toastify'
import {
  getMyModulesDataSharedThunk,
  deleteAllModulesDataSharingThunk,
  deleteModuleDataSharingThunk,
} from './dataSharingThunk'

const initialState = {
  isLoading: false,
  moduleDataSharings: [],
}

export const deleteAllModulesDataSharings = createAsyncThunk(
  'dataSharing/deleteAllModulesDataSharings',
  async (thunkAPI) => {
    return deleteAllModulesDataSharingThunk(thunkAPI)
  }
)
export const getMyModulesDataShared = createAsyncThunk(
  'dataSharing/getMyModulesDataShared',
  async (thunkAPI) => {
    return getMyModulesDataSharedThunk(thunkAPI)
  }
)

export const deleteModuleDataSharing = createAsyncThunk(
  'dataSharing/deleteModuleDataSharing',
  async (modules, thunkAPI) => {
    return deleteModuleDataSharingThunk(modules, thunkAPI)
  }
)

const dataSharingSlice = createSlice({
  name: 'dataSharing',
  initialState,
  extraReducers: (builder) => {
    builder
      .addCase(getMyModulesDataShared.pending, (state) => {
        state.isLoading = true
      })
      .addCase(getMyModulesDataShared.fulfilled, (state, { payload }) => {
        const { moduleDataSharing } = payload
        state.isLoading = false
        state.moduleDataSharings = moduleDataSharing
      })
      .addCase(getMyModulesDataShared.rejected, (state, { payload }) => {
        state.isLoading = false
        toast.error(payload)
      })
      .addCase(deleteModuleDataSharing.pending, (state) => {
        state.isLoading = true
      })
      .addCase(deleteModuleDataSharing.fulfilled, (state) => {
        state.isLoading = false
        toast.success(`Données partagées mises à jour !`)
      })
      .addCase(deleteModuleDataSharing.rejected, (state, { payload }) => {
        state.isLoading = false
        toast.error(payload)
      })
      .addCase(deleteAllModulesDataSharings.pending, (state) => {
        state.isLoading = true
      })
      .addCase(deleteAllModulesDataSharings.fulfilled, (state) => {
        state.isLoading = false
        toast.success(`Toutes vos données sont retournées privées`)
      })
      .addCase(deleteAllModulesDataSharings.rejected, (state, { payload }) => {
        state.isLoading = false
        toast.error(payload)
      })
  },
})

export default dataSharingSlice.reducer
