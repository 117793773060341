import { FaCalendarAlt } from 'react-icons/fa'
import { IoGameControllerOutline } from 'react-icons/io5'
import { IoLogoGameControllerB } from 'react-icons/io'
import { Link } from 'react-router-dom'
import Wrapper from '../assets/wrappers/ModuleCard'
import moment from 'moment/moment'
import IconText from './IconText'
import ModuleRecordingsPanel from './ModuleRecordingsPanel'

const ModuleCard = ({
  _id,
  name,
  shortResume,
  thumb,
  tags,
  numOfLaunched,
  numOfFinished,
  createdAt,
  updatedAt,
  status,
  myRecordings,
}) => {
  const createDate = `mis en ligne : ${moment(createdAt)
    .startOf('day')
    .fromNow()}`
  const updateDate = `mis à jour : ${moment(updatedAt)
    .startOf('day')
    .fromNow()}`
  const path = `/catalog/${_id}`
  const stringNumberOfLaunched = `participants : ${numOfLaunched}`
  const stringNumberOfFinished = `terminé : ${numOfFinished}`

  return (
    <Wrapper>
      <header>
        {myRecordings && <ModuleRecordingsPanel recordings={myRecordings} />}
        <div className='info'>
          <h5>{name}</h5>
          <p>{shortResume}</p>
          <Link to={path} className='btn'>
            acceder au module
          </Link>
        </div>
      </header>
      <div className='content'>
        <div className='content-center'>
          <IconText
            icon={<IoGameControllerOutline />}
            text={stringNumberOfLaunched}
          />
          <IconText
            icon={<IoLogoGameControllerB />}
            text={stringNumberOfFinished}
          />
          <IconText icon={<FaCalendarAlt />} text={createDate} />
          <IconText icon={<FaCalendarAlt />} text={updateDate} />
          <div className={`status ${status}`}>{status}</div>
        </div>
      </div>
      <hr />
    </Wrapper>
  )
}

export default ModuleCard
