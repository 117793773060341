import { SearchContainer, ModuleContainer } from '../components'

const Catalog = () => {
  return (
    <>
      <SearchContainer />
      <ModuleContainer />
    </>
  )
}
export default Catalog
