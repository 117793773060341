import { Link } from 'react-router-dom'

const Error = () => {
  return (
    <div className='full-page'>
      <div>
        <h3>404</h3>
        <p>Page non trouvée</p>
        <Link to='/'>Retournez sur la page d'accueil</Link>
      </div>
    </div>
  )
}
export default Error
