import { FormCheckBox, Loading } from '.'
import Wrapper from '../assets/wrappers/SearchContainer'
import { useSelector, useDispatch } from 'react-redux'
import {
  getMyModulesDataShared,
  deleteModuleDataSharing,
} from '../features/dataSharing/dataSharingSlice'
import { useEffect, useState } from 'react'

const DataSharingContainer = () => {
  const dispatch = useDispatch()

  const { isLoading, moduleDataSharings } = useSelector(
    (store) => store.dataSharing
  )

  const [values, setValues] = useState([])

  useEffect(() => {
    dispatch(getMyModulesDataShared())
  }, [])

  const toggleCheckbox = (e) => {
    const name = e.target.name
    if (values.indexOf(name) === -1) {
      setValues([...values, name])
    } else {
      setValues([...values].filter((e) => e !== name))
    }
  }

  const onSubmit = (e) => {
    e.preventDefault()
    if (values.length > 0) {
      dispatch(deleteModuleDataSharing({ values, setValues })).then(() => {
        dispatch(getMyModulesDataShared()) // chained dispach to get new shared list
      })
      setValues([])
    }
    return
  }

  if (isLoading) {
    return <Loading center />
  }

  if (moduleDataSharings.length === 0) {
    return (
      <Wrapper>
        <form className='form'>
          <h5>Pas de partage de données</h5>
        </form>
      </Wrapper>
    )
  }

  return (
    <Wrapper>
      <form className='form'>
        <h5>Mon centre de partage de données</h5>
        <div className='form-center'>
          {moduleDataSharings.map((moduleDataSharing) => {
            return (
              <FormCheckBox
                key={moduleDataSharing.module}
                name={moduleDataSharing.module}
                checked={values.indexOf(moduleDataSharing.module) !== -1}
                handleChange={toggleCheckbox}
                text={moduleDataSharing.moduleInfo.name}
                float='right'
                alignItems='center'
              />
            )
          })}
          <button
            className='btn btn-block'
            disabled={isLoading || values.length === 0}
            onClick={onSubmit}
          >
            Retirer les partages de données
          </button>
        </div>
      </form>
    </Wrapper>
  )
}
export default DataSharingContainer
