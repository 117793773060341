import Wrapper from '../assets/wrappers/BigLogo'
import logo from '../assets/images/lagoon-logo.png'

const BigLogo = () => {
  return (
    <Wrapper>
      <img className='logo' src={logo} alt='Lagoon Learn for life'></img>
    </Wrapper>
  )
}
export default BigLogo
