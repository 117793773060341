import Wrapper from '../assets/wrappers/PasswordCheckList'
import ReactPasswordCheckList from 'react-password-checklist'

const PasswordCheckList = ({ values, setValues }) => {
  const password = values.password
  const password2 = values.password2
  return (
    <Wrapper>
      {/* verify password with react-password-checklist */}

      <ReactPasswordCheckList
        className='password-checklist'
        rules={['minLength', 'specialChar', 'number', 'capital', 'match']}
        minLength={8}
        value={password}
        valueAgain={password2}
        onChange={(isValid) => {
          setValues({ ...values, passwordValid: isValid })
        }}
        messages={{
          minLength: 'La taille du mot de passe doit dépasser 7 caractères',
          specialChar:
            'Le mot de passe doit contenir au moins un catactère spécial',
          number: 'Le mot de passe doit contenir au moins un nombre',
          capital:
            'Le mot de passe doit contenir au moins une lettre en majuscule.',
          match: 'Les mots de passe ne sont pas identiques',
        }}
      />
    </Wrapper>
  )
}
export default PasswordCheckList
