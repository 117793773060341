import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { logout } from '../features/user/userSlice'
import { useNavigate } from 'react-router-dom'
import { useRedirection } from '../utils/queryParams'

const Logout = () => {
  const redirect = useRedirection()
  const navigate = useNavigate()
  const { user, isLoading } = useSelector((store) => store.user)
  const dispatch = useDispatch()

  useEffect(() => {
    if (user) {
      dispatch(logout())
    }
  }, [])

  useEffect(() => {
    if (!isLoading && !user) {
      // redirect to login page
      if (redirect) {
        navigate(`/login?redirect=${redirect}`)
      } else {
        navigate('/login')
      }
    }
  }, [user])

  return <></>
}
export default Logout
