import { useEffect, useState } from 'react'
import { Link, useLocation } from 'react-router-dom'
//import Wrapper from '../assets/wrappers/Verify'
import Wrapper from '../assets/wrappers/Register'
import { toast } from 'react-toastify'
import { useSelector, useDispatch } from 'react-redux'
import { verifyUser, confirmAccount } from '../features/user/userSlice'
import {
  Loading,
  FormRow,
  BigLogo,
  TwoLinesTitle,
  PasswordCheckList,
} from '../components'

function useQuery() {
  return new URLSearchParams(useLocation().search)
}

const initialState = {
  password: '',
  password2: '',
  passwordValid: false,
  email: '',
  verificationToken: '',
}

const Verify = () => {
  const query = useQuery()
  const { isLoading, isVerified, isPasswordChanged } = useSelector(
    (store) => store.user
  )
  const [values, setValues] = useState(initialState)

  const dispatch = useDispatch()

  useEffect(() => {
    const email = query.get('email')
    const verificationToken = query.get('token')
    setValues({
      ...values,
      email: email,
      verificationToken: verificationToken,
    })
    dispatch(verifyUser({ email, verificationToken }))
  }, [])

  const handleChange = (e) => {
    const name = e.target.name
    const value = e.target.value
    setValues({ ...values, [name]: value })
  }

  const onSubmit = (e) => {
    e.preventDefault()
    const { email, verificationToken, password, password2, passwordValid } =
      values
    if (!password || !password2) {
      toast.error('Merci de renseigner tous les champs')
      return
    }
    if (!passwordValid) {
      toast.error(`Le mot de passe n'est pas conforme`)
      return
    }
    dispatch(confirmAccount({ password, email, verificationToken }))
    // reset all cells
    setValues({
      ...values,
      password: '',
      password2: '',
    })
  }

  if (isLoading) {
    return <Loading center />
  }

  if (isVerified) {
    return (
      <Wrapper className='full-page'>
        <form className='form' onSubmit={onSubmit}>
          {/* Big Logo */}
          <BigLogo />
          <hr />
          {!isPasswordChanged ? (
            <>
              {/* Two Lines Title */}
              <TwoLinesTitle title1='Choisir' title2='son mot de passe' />
              <hr />

              {/*password 1 */}
              <FormRow
                type='password'
                name='password'
                value={values.password}
                handleChange={handleChange}
                placeholder='Mot de passe'
              />

              {/*password 2 */}
              <FormRow
                type='password'
                name='password2'
                value={values.password2}
                handleChange={handleChange}
                placeholder='Confirmer son mot de passe'
              />

              <PasswordCheckList
                values={values}
                password2={values}
                setValues={setValues}
              />

              <button
                type='submit'
                className='btn btn-block'
                disabled={isLoading}
              >
                {isLoading ? 'Chargement ...' : 'Valider'}
              </button>
            </>
          ) : (
            <div className='center'>
              <p>Votre compte à bien été vérifié !</p>
              <p>
                Vous pouvez vous connecter à la plateforme Lagoon avec vos
                identifiants.
              </p>
              <Link to='/login' className='btn'>
                Login
              </Link>
            </div>
          )}
        </form>
      </Wrapper>
    )
  }

  return <Wrapper className='full-page'> </Wrapper>
}
export default Verify
