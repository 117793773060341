import { configureStore } from '@reduxjs/toolkit'
import userSlice from './features/user/userSlice'
import subscriptionSlice from './features/subscription/subscriptionSlice'
import dataSharingSlice from './features/dataSharing/dataSharingSlice'
import allModulesSlice from './features/allModules/allModulesSlice'
import moduleInfoSlice from './features/moduleInfo/moduleInfoSlice'

export const store = configureStore({
  reducer: {
    user: userSlice,
    subscription: subscriptionSlice,
    dataSharing: dataSharingSlice,
    allModules: allModulesSlice,
    moduleInfo: moduleInfoSlice,
  },
})
