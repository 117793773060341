import { FaWpforms } from 'react-icons/fa'
import { ImProfile } from 'react-icons/im'

const links = [
  {
    id: 1,
    text: 'accueil',
    path: '/',
    icon: <ImProfile />,
  },
  {
    id: 2,
    text: 'catalogue',
    path: '/catalog',
    icon: <FaWpforms />,
  },
]

export default links
