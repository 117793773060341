import styled from 'styled-components'

const Wrapper = styled.div`
  text-align: center;
  image {
    display: block;
    margin: 0 auto;
    margin-bottom: 1rem;
  }
`
export default Wrapper
